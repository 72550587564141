* {
  margin: 0;
  padding: 0;
}

body {
  font-family: Open Sans, sans-serif;
}

.page {
  width: min(90%, 1200px);
  margin: 0 auto;
}

header {
  margin-bottom: 5em;
  position: relative;
}

h1 {
  text-align: center;
  color: #fff;
  mix-blend-mode: difference;
  padding: .5em 0;
  font-size: clamp(4rem, 18.5vw, 20rem);
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

header img {
  max-width: 100%;
  display: block;
}

h1, h2 {
  font-family: Playfair Display, serif;
  font-weight: normal;
}

.grid {
  grid-gap: 2em 0;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.item {
  color: #333;
  place-self: center;
  text-decoration: none;
}

.item:nth-child(2n+1) {
  justify-self: start;
}

.item:nth-child(2n) {
  justify-self: end;
}

.item img {
  width: 100%;
  opacity: 0;
  display: block;
}

.item__image {
  margin-bottom: .6em;
  position: relative;
}

.item__meta {
  transform-origin: 0 100%;
  text-transform: uppercase;
  font-size: 80%;
  position: absolute;
  bottom: .4em;
  left: -.4em;
  transform: rotate(-90deg);
}

.item__title {
  margin-bottom: .4em;
  font-size: 40px;
  line-height: 1.2;
}

.item_p {
  font-size: 1em;
  line-height: 1.4;
}

.item_h {
  width: 100%;
}

.item_v {
  width: 80%;
}

.footer {
  border-top: 1px solid #ccc;
  margin-top: 8em;
  padding: 1em 0 4em;
  font-size: 15px;
  line-height: 1.5;
  display: flex;
}

.footer a {
  color: #333;
}

.footer p + p {
  text-align: right;
  margin-left: auto;
}

@media screen and (max-width: 600px) {
  .grid {
    display: block;
  }

  .item_v, .item_h {
    width: auto;
    margin-bottom: 3em;
  }

  .item img {
    width: 100%;
    max-width: none;
  }
}

#container {
  height: 100vh;
  width: 100vw;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
}

/*# sourceMappingURL=index.95c2b60d.css.map */
