* {
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Open Sans', sans-serif;
}
.page {
  width: min(90%, 1200px);
  margin: 0 auto;
  /* border: 1px solid #000; */
}
/* header */

header {
  margin-bottom: 5em;
  position: relative;
}
h1 {
  font-size: 260px;
  line-height: 1;
  padding: 0.5em 0;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  mix-blend-mode: difference;
  font-size: 18vw;
  font-size: clamp(4rem, 18.5vw, 20rem);
}
header img {
  max-width: 100%;
  display: block;
}
h1,
h2 {
  font-family: 'Playfair Display', serif;
  font-weight: normal;
}

/* grid */
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em 0;
}

.item {
  /* border: 1px solid #000; */
  align-self: center;
  justify-self: center;
  text-decoration: none;
  color: #333;
}
.item:nth-child(odd) {
  justify-self: start;
}
.item:nth-child(even) {
  justify-self: end;
}
.item img {
  width: 100%;
  display: block;
  opacity: 0;
}
.item__image {
  position: relative;
  margin-bottom: 0.6em;
}
.item__meta {
  position: absolute;
  left: -0.4em;
  bottom: 0.4em;
  transform-origin: 0 100%;
  transform: rotate(-90deg);
  text-transform: uppercase;
  font-size: 80%;
}
.item__title {
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 0.4em;
}
.item_p {
  line-height: 1.4;
  font-size: 1em;
}
.item_h {
  width: 100%;
}
.item_v {
  width: 80%;
}

/* footer */
.footer {
  border-top: 1px solid #ccc;
  padding: 1em;
  font-size: 15px;
  display: flex;
  margin-top: 8em;
  line-height: 1.5;
  padding: 1em 0 4em 0;
}
.footer a {
  color: #333;
}
.footer p + p {
  margin-left: auto;
  text-align: right;
}

@media screen and (max-width: 600px) {
  .grid {
    display: block;
  }
  .item_v,
  .item_h {
    width: auto;
    margin-bottom: 3em;
  }
  .item img {
    width: 100%;
    max-width: none;
  }
}

#container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
}
